// App.scss

// Include any default variable overrides here (though functions won't be available)
// Invoke

$primary: #bf1e2d;
$secondary: #dddddd;

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here